'use strict';

var util = require('../components/util');

var $form; var $requiredInputs; var validator;

/**
 * @function validateForm()
 * @description
 */
var validateForm = function () {
	if (!validator) {
		return;
	}

	// Scroll to an error if we see one
	var errorFound = $('.inputfield.field-error').first();
	if (errorFound.length) {
		$("html, body").animate({scrollTop: errorFound.offset().top - 50}, 250);
	}
};

/**
 * @function validateElChange()
 * @description
 */
var validateElChange = function () {

	$(this).parent().find("span.field-error").remove();
	$(this).removeClass("field-error");

	$(this).parent().find("span.field-success").remove();
	$(this).removeClass("field-success");

	if ($(this).val() === '') {
		validator.element(this);
	}

};

/**
 * @function validateShippingForm()
 * @description
 */
var validateShippingForm = function () {
	var viewPosition = $(window).scrollTop();

	$form.find("input.required, input.validate, select.required").each(function () {
		$(this).focus().blur();
	});

	// Run full form validation
	validateForm();

	$("html").scrollTop(viewPosition);
};

/**
 * @function validateBillingForm()
 * @description
 */
var validateBillingForm = function (validateOnlyAddress) {
	var viewPosition = $(window).scrollTop();

	if (validateOnlyAddress) {
		$(".billing-address").find("input.required, select.required").each(function () {
			$(this).focus().blur();
		});
	} else {
		$form.find("input.required, select.required").each(function () {
			$(this).focus().blur();
		});
	}

	// Run full form validation
	validateForm();

	$("html").scrollTop(viewPosition);
};

/**
 * @function validateGenericForm()
 * @description
 */
var validateGenericForm = function () {
	var viewPosition = $(window).scrollTop();

	$form.find("input.required, select.required").each(function () {
		$(this).focus().blur();
	});

	// Run full form validation
	validateForm();

	$("html").scrollTop(viewPosition);
};

/**
 * @function resetForm()
 * @description
 */
var resetForm = function () {
	$form.find(".field-error").removeClass(".field-error");
	$form.find(".field-success").removeClass("field-success");

	validator.resetForm();
};

/**
 * @function init()
 * @description
 */
var init = function (opts) {
	if (!opts.formSelector || !opts.continueSelector) {
		throw new Error('Missing form and continue action selectors.');
	}
	$form = $(opts.formSelector);
	validator = $form.validate();
	$requiredInputs = $form.find("input.required, input.validate, select.required, textarea.required").not('input[type=checkbox]');

	// Upon page load, validate only the non empty fields.
	$requiredInputs.each(function (index, input) {
		if (input.value !== '') {
			validator.element(input);
		}
	});

	validateForm();

	util.checkInputVal($form.find("input"));

	// start listening
	$requiredInputs.filter('select').on('change', validateElChange);
};

exports.init = init;
exports.validateForm = validateForm;
exports.validateShippingForm = validateShippingForm;
exports.validateBillingForm = validateBillingForm;
exports.validateGenericForm = validateGenericForm;
exports.resetForm = resetForm;
