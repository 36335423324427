'use strict';

var inputs = {
	init: function (el) {
		$(".inputfield input, .inputfield textarea", el).each(function() {
			var input = $(this);
			if (!input.val()) {
				input.attr('data-empty', true);
			}
		});

		$(".inputfield input, .inputfield textarea", el).off('input.empty').on('input.empty', function (e) {
			$(e.currentTarget).attr('data-empty', !e.currentTarget.value);
		});
	}
};

module.exports = inputs;
