'use strict';

var AccessibilityHelper = require('../helper/accessibility-helper');
var mediumBp = 900;

var accordion = {
	init: function () {
		// (Mobile only) navigation on account pages
		if (window.innerWidth <= mediumBp) {
			if ($(".pt_account .account-nav")) {
				accordion.attach('.col-left .account-nav', '.account-nav-trigger', '.account-nav-content', 'closed', triggerSwitch());
			}
		}

		// moves the current nav text to the trigger
		function triggerSwitch() {
			$('.account-nav-trigger').html($('.account-nav-content .on').text());
			$('.account-nav-content .on').hide();
		}
	},

	/**
	 * @function attach()
	 * @description Trigger the accordion functionality on a container
	 * @param {String} target The container selector string
	 * @param {String} trigger Optional The trigger selector to open/close
	 * @param {String} content Optional The internal content selector to show/hide
	 * @param {String} initial Optional The initial state to show (open / closed)
	 * @param {String} callback Optional A callback function triggered on click
	 *
	 * Recommended to add `display:none` on the content html (or use default class)
	 * if you want to prevent display flash as JS loads
	 */
	attach: function (target, trigger, content, initial, callback) {
		// Initial escape
		if (!$(target).length > 0) {
			return;
		}

		// Defaults
		trigger = typeof trigger !== 'undefined' ? trigger : '.accordion-trigger';
		content = typeof content !== 'undefined' ? content : '.accordion-content';
		initial = typeof initial !== 'undefined' ? initial : 'open';

		var thisTrigger = $(target).find(trigger);
		var thisContent = $(target).find(content);

		// Class name standard for styles
		thisTrigger.addClass('accordion-trigger focusable').attr('tabindex', '0');
		thisContent.addClass('accordion-content');

		// Assume open, otherwise close
		if (initial === 'closed') {
			thisTrigger.addClass('accordion-trigger-closed');
			thisContent.css({'display': 'block', 'height': '0'}).addClass('accordion-closed');
			AccessibilityHelper.toggleAccessibility(thisContent, false);
		} else {
			thisTrigger.removeClass('accordion-trigger-closed');
			accordion.openAccordion(thisContent);
			AccessibilityHelper.toggleAccessibility(thisContent, true);
		}

		//  ---- Trigger event
		$(target).on("click", trigger, function () {
			openOrCloseAccordion();
		});
		$(target).on("keypress", trigger, function (e) {
			e.preventDefault();
			AccessibilityHelper.onSpaceOrEnterAction(e, openOrCloseAccordion);
		});

		function openOrCloseAccordion() {
			thisTrigger.toggleClass('accordion-trigger-closed');

			if (thisContent.height() === 0) {
				accordion.openAccordion(thisContent);
			} else {
				accordion.closeAccordion(thisContent);
			}

			if (callback !== undefined) {
				callback();
			}
		}
	},

	// ---- Open sequence
	openAccordion: function (thisContent) {
		// Figure out auto height
		var curHeight = thisContent.height();
		var autoHeight = thisContent.css({'display': 'block', 'height': 'auto'}).height();

		thisContent.trigger('accordionOpening');
		AccessibilityHelper.toggleAccessibility(thisContent, true);
		thisContent.height(curHeight).stop().animate({
			height: autoHeight,
			opacity: '1'
		}, 250, function () {
			thisContent.css('height', 'auto').removeClass('accordion-closed');
		});
	},

	// ---- Close sequence
	closeAccordion: function (thisContent) {
		AccessibilityHelper.toggleAccessibility(thisContent, false);
		thisContent.stop().animate({
			height: '0',
			opacity: '0'
		}, 250, function () {
			thisContent.addClass('accordion-closed').trigger('accordionClosed');
		});
	}
};

module.exports = accordion;
