'use strict';

var _capitalize = require('lodash/capitalize');

var util = {
	/**
	 * @function
	 * @description appends the parameter with the given name and value to the given url and returns the changed url
	 * @param {String} url the url to which the parameter will be added
	 * @param {String} name the name of the parameter
	 * @param {String} value the value of the parameter
	 */
	appendParamToURL: function (url, name, value) {
		// quit if the param already exists
		if (url.indexOf(name + '=') !== -1) {
			return url;
		}
		var separator = url.indexOf('?') !== -1 ? '&' : '?';
		return url + separator + name + '=' + encodeURIComponent(value);
	},

	/**
	 * @function
	 * @description remove the parameter and its value from the given url and returns the changed url
	 * @param {String} url the url from which the parameter will be removed
	 * @param {String} name the name of parameter that will be removed from url
	 */
	removeParamFromURL: function (url, name) {
		if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
			return url;
		}
		var hash;
		var params;
		var domain = url.split('?')[0];
		var paramUrl = url.split('?')[1];
		var newParams = [];
		// if there is a hash at the end, store the hash
		if (paramUrl.indexOf('#') > -1) {
			hash = paramUrl.split('#')[1] || '';
			paramUrl = paramUrl.split('#')[0];
		}
		params = paramUrl.split('&');
		for (var i = 0; i < params.length; i++) {
			// put back param to newParams array if it is not the one to be removed
			if (params[i].split('=')[0] !== name) {
				newParams.push(params[i]);
			}
		}
		return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
	},

	/**
	 * @function
	 * @description appends the parameters to the given url and returns the changed url
	 * @param {String} url the url to which the parameters will be added
	 * @param {Object} params
	 */
	appendParamsToUrl: function (url, params) {
		var _url = url;
		Object.keys(params).forEach( (key) => {
			_url = this.appendParamToURL(_url, key, params[key]);
		});
		return _url;
	},
	/**
	 * @function
	 * @description extract the query string from URL
	 * @param {String} url the url to extra query string from
	 **/
	getQueryString: function (url) {
		var qs;
		if (typeof url !== 'string') {
			return;
		}
		var a = document.createElement('a');
		a.href = url;
		if (a.search) {
			qs = a.search.substr(1); // remove the leading ?
		}
		return qs;
	},
	/**
	 * @function
	 * @description
	 * @param {String}
	 * @param {String}
	 */
	elementInViewport: function (el, offsetToTop) {
		var top = el.offsetTop;
		var left = el.offsetLeft;
		var width = el.offsetWidth;
		var height = el.offsetHeight;

		while (el.offsetParent) {
			el = el.offsetParent;
			top += el.offsetTop;
			left += el.offsetLeft;
		}

		if (typeof(offsetToTop) !== 'undefined') {
			top -= offsetToTop;
		}

		if (window.pageXOffset !== null) {
			return (
				top < (window.pageYOffset + window.innerHeight) &&
				left < (window.pageXOffset + window.innerWidth) &&
				(top + height) > window.pageYOffset &&
				(left + width) > window.pageXOffset
			);
		}

		if (document.compatMode === 'CSS1Compat') {
			return (
				top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
				left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
				(top + height) > window.document.documentElement.scrollTop &&
				(left + width) > window.document.documentElement.scrollLeft
			);
		}
	},

	/**
	 * @function
	 * @description Appends the parameter 'format=ajax' to a given path
	 * @param {String} path the relative path
	 */
	ajaxUrl: function (path) {
		return this.appendParamToURL(path, 'format', 'ajax');
	},

	/**
	 * @function
	 * @description
	 * @param {String} url
	 */
	toAbsoluteUrl: function (url) {
		if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
			url = '/' + url;
		}
		return url;
	},
	/**
	 * @function
	 * @description Loads css dynamically from given urls
	 * @param {Array} urls Array of urls from which css will be dynamically loaded.
	 */
	loadDynamicCss: function (urls) {
		var i; var len = urls.length;
		for (i = 0; i < len; i++) {
			this.loadedCssFiles.push(this.loadCssFile(urls[i]));
		}
	},

	/**
	 * @function
	 * @description Loads css file dynamically from given url
	 * @param {String} url The url from which css file will be dynamically loaded.
	 */
	loadCssFile: function (url) {
		return $('<link/>').appendTo($('head')).attr({
			type: 'text/css',
			rel: 'stylesheet'
		}).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
	},
	// array to keep track of the dynamically loaded CSS files
	loadedCssFiles: [],

	/**
	 * @function
	 * @description Removes all css files which were dynamically loaded
	 */
	clearDynamicCss: function () {
		var i = this.loadedCssFiles.length;
		while (0 > i--) {
			$(this.loadedCssFiles[i]).remove();
		}
		this.loadedCssFiles = [];
	},
	/**
	 * @function
	 * @description Extracts all parameters from a given query string into an object
	 * @param {String} qs The query string from which the parameters will be extracted
	 */
	getQueryStringParams: function (qs) {
		if (!qs || qs.length === 0) {
			return {};
		}
		var params = {};
		var unescapedQS = decodeURIComponent(qs);
		// Use the String::replace method to iterate over each
		// name-value pair in the string.
		unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
			function ($0, $1, $2, $3) {
				params[$1] = $3;
			}
		);
		return params;
	},

	fillAddressFields: function (address, $form) {
		for (var field in address) {
			if (field === 'ID' || field === 'UUID' || field === 'key') {
				continue;
			}
			// if the key in address object ends with 'Code', remove that suffix
			// keys that ends with 'Code' are postalCode, stateCode and countryCode
			$form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
			// update the state fields
			if (field === 'countryCode') {
				$form.find('[name$="country"]').trigger('change');
				// retrigger state selection after country has changed
				// this results in duplication of the state code, but is a necessary evil
				// for now because sometimes countryCode comes after stateCode
				$form.find('[name$="state"]').val(address.stateCode);
			}
		}
	},
	/**
	 * @function
	 * @description Updates the number of the remaining character
	 * based on the character limit in a text area
	 */
	limitCharacters: function () {
		$('form').find('textarea[data-character-limit]').each(function () {
			var characterLimit = $(this).data('character-limit');
			var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
				'<span class="char-remain-count">' + characterLimit + '</span>',
				'<span class="char-allowed-count">' + characterLimit + '</span>');
			var charCountContainer = $(this).next('div.char-count');
			if (charCountContainer.length === 0) {
				charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
			}
			charCountContainer.html(charCountHtml);
			// trigger the keydown event so that any existing character data is calculated
			$(this).change();
		});
	},
	/**
	 * @function
	 * @description Binds the onclick-event to a delete button on a given container,
	 * which opens a confirmation box with a given message
	 * @param {String} container The name of element to which the function will be bind
	 * @param {String} message The message the will be shown upon a click
	 */
	setDeleteConfirmation: function (container, message) {
		$(container).on('click', '.delete', function () {
			return window.confirm(message);
		});
	},
	/**
	 * @function
	 * @description Scrolls a browser window to a given x point
	 * @param {String} The x coordinate
	 */
	scrollBrowser: function (xLocation) {
		$('html, body').animate({scrollTop: xLocation}, 500);
	},

	isMobile: function () {
		var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
		var	idx = 0;
		var isMobile = false;
		var userAgent = (navigator.userAgent).toLowerCase();

		while (mobileAgentHash[idx] && !isMobile) {
			isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
			idx++;
		}
		return isMobile;
	},

	uncheckCheckbox: function(obj) {
		var $square = $(obj).find(".icon");
		/* only uncheck if checked */
		if ($square.hasClass("icon-icon-checked")) {
			$square.removeClass("icon-icon-checked").addClass("icon-icon-unchecked");
		}
	},

	scrollHelperForMobileDevices: function(el) {

		var _clientY = null; // remember Y position on touch start

		el[0].addEventListener('touchstart', function (event) {
			if (event.targetTouches.length === 1) {
				// detect single touch
				_clientY = event.targetTouches[0].clientY;
			}
		}, false);

		el[0].addEventListener('touchmove', function (event) {
			if (event.targetTouches.length === 1) {
				// detect single touch
				disableRubberBand(event);
			}
		}, false);

		function disableRubberBand(event) {
			var clientY = event.targetTouches[0].clientY - _clientY;

			if (el[0].scrollTop === 0 && clientY > 0) {
				// element is at the top of its scroll
				event.preventDefault();
			}

			if (isOverlayTotallyScrolled() && clientY < 0) {
				// element is at the top of its scroll
				event.preventDefault();
			}
		}

		function isOverlayTotallyScrolled() {
			// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
			return el[0].scrollHeight - el[0].scrollTop <= el[0].clientHeight;
		}
	},

	/**
	 * @function updateInputTypes()
	 * @description change input type on client side for more friendly UI
	 * @param {Element} input The targeted element to change
	 * @param {String} type What to change the input type to
	 * @param {String} pattern Optional pattern attribute to add
	 */
	updateInputTypes: function(input, type, pattern) {
		input.prop('type', type);

		if (typeof pattern !== 'undefined') {
			input.attr('pattern', pattern);
		}
	},

	/**
	 * @function checkInputVal()
	 * @description look at a input and define the data-empty value
	 * @param {Array / Element} input The targeted element(s) to see if it has a value
	 */
	checkInputVal: function(inputs) {
		// Passed an Array
		if (inputs.constructor === Array) {
			inputs.forEach(function(element) {
				if (element.val() === '') {
					element.attr('data-empty', true);
				} else {
					element.attr('data-empty', false);
				}
			});
		// Passed an element or jQuery selector
		} else {
			inputs.each(function() {
				if ($(this).val() === '') {
					$(this).attr('data-empty', true);
				} else {
					$(this).attr('data-empty', false);
				}
			});
		}
	},

	/**
	 * @function matchIBCardType()
	 * @description convert long credit card type that used by cleave.js or card-validator to match with icebreaker's short format
	 * @param {String} type credit card type in long/dash format
	 */
	matchIBCardType: function(type) {
		var result;
		switch (type) {
		case "american-express":
			result = "amex";
			break;
		case "mastercard":
			result = "master";
			break;
		default:
			result = type || "";
			break;
		}
		return _capitalize(result);
	},

	/** Shared function to control the background scrolling when opening and
	 * closing the search suggestions popup. Can possible be used for other
	 * components but is untested.
	 * @param {boolean} toDisable
	 */
	disableBackgroundScrolling(toDisable) {
		if (toDisable) {
			// console.log('Disabling scrolling mobile');
			$('html').addClass('disable-background-scroll__html');
			$('body').addClass('disable-background__body');
		} else {
			// console.log('enabling scrolling mobile');
			$('html').removeClass('disable-background-scroll__html');
			$('body').removeClass('disable-background__body');
		}
	},

	/**
	 * @function openBase64Document()
	 * @description open a document (base64 encoded string format) in new browser window/tab
	 * @param {String} base64Str base64 encoded string of the document
	 * @param {String} mimeType MIME content type of the document
	 */
	openBase64Document: function(base64Str, mimeType) {
		// Some modern browser does not allow to navigate top frame to data URL
		// * Most browsers will stop opening data URL from javascript like
		// 	 `window.open("data:image/png;base64,.....", "_blank");`
		// * iOS Safari will prevent it from both js and data URL href link
		// Reference https://developer.mozilla.org/en-US/docs/Web/API/Blob
		var typedArray = this.base64ToArray(base64Str);
		var blob = new Blob([typedArray.buffer], {type: mimeType});
		window.open(URL.createObjectURL(blob), "_blank");
	},

	/**
	 * @function base64ToArray()
	 * @description convert base64 based string to typed array which will be
	 * 							consumed by `new Blob()`
	 * @param {String} base64Str base64 encoded string
	 * @returns
	 */
	base64ToArray: function(base64Str) {
		var binaryString = window.atob(base64Str);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	},

	/**
	 * @function getYouTubeIdFromLink()
	 * @description get youtube video id from youtube link url
	 * @param {String} url youtube video link url
	 * @returns youtube id string
	 */
	getYouTubeIdFromLink: function(url) {
		let ytId = "";
		try {
			ytId = url.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&?/#]+)/)[1];
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error("Supplied YouTube url is invalid.");
		}
		return ytId;
	}
};

module.exports = util;
