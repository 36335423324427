const _componentForm = {
	premise: 'short_name',
	subpremise: 'short_name',
	street_number: 'short_name',
	route: 'short_name',
	locality: 'long_name',
	sublocality_level_1: 'long_name',
	administrative_area_level_1: 'short_name',
	country: 'short_name',
	postal_code: 'short_name'
};

let $address1;
let $address2;
let $city;
let $postalCode;
let $state;
let $country;

let componentToID;
let autocomplete;
let handleSelection;

/**
 * @function fillInAddress()
 * @description
 */
const fillInAddress = function () {
	// Get the place details from the autocomplete object.
	const place = autocomplete.getPlace();

	if (place && place.address_components) {
		// Remove address1 entry to clear way for found address
		$address1.val("");
		$address2.val("");

		// Get each component of the address from the place details
		let premise;
		let subpremise;
		for (let i = 0; i < place.address_components.length; i++) {
			const addressType = place.address_components[i].types[0];
			const val = place.address_components[i][_componentForm[addressType]];
			if (_componentForm[addressType]) {
				if (addressType != "route") {
					if (addressType == 'subpremise') {
						// Keep the value - subpremise will be prepended to the street number.
						subpremise = val;
					} else if (addressType == 'premise') {
						// Keep the value - subpremise will be prepended to the street number.
						premise = val;
					} else {
						$("#" + componentToID[addressType]).val(val);
					}
				} else {
					const route = place.address_components[i][_componentForm[addressType]];
					$("#" + componentToID[addressType]).val($("#" + componentToID[addressType]).val() + " " + route);
				}
			}
		}

		// Prepend premise and subpremise if applicable.
		if (premise || subpremise) {
			let address1Value = $address1.val();
			if (subpremise) {
				address1Value = subpremise + '/' + address1Value;
			}
			if (premise) {
				address1Value = premise + ', ' + address1Value;
			}
			$address1.val(address1Value);
		}

		if (handleSelection) {
			handleSelection(place);
		}
	}
};

/**
 * Initializes the address suggest component.
 * @param address1 jQuery form field
 * @param address2 jQuery form field
 * @param city jQuery form field
 * @param postalCode jQuery form field
 * @param state jQuery form field
 * @param country jQuery form field
 * @param shipToCountry country code
 * @param onSelection callback function to be called upon selection.
 */
const init = ({
	address1,
	address2,
	city,
	postalCode,
	state,
	country,
	shipToCountry,
	onSelection
}) => {
	const countryCode = shipToCountry.toLowerCase();

	$address1 = address1;
	$address2 = address2;
	$city = city;
	$postalCode = postalCode;
	$state = state;
	$country = country;

	// Callback function
	handleSelection = onSelection;

	const options = {
		types: ['geocode'],
		componentRestrictions: {country: countryCode}
	};
	autocomplete = new google.maps.places.Autocomplete($address1[0], options);
	autocomplete.addListener('place_changed', fillInAddress);

	$address1.attr("placeholder", "");

	componentToID = {
		street_number: $address1.attr("name"),
		route: $address1.attr("name"),
		locality: $city.attr("name"),
		sublocality_level_1: $address2.attr("name"),
		administrative_area_level_1: $state.attr("name"),
		country: $country.attr("name"),
		postal_code: $postalCode.attr("name")
	};
};

module.exports = {
	init: init
};
