// This file is dedicate to the sign up / sign in logic used in
// the Vue sign-up-process-wrapper component

// Following logic is the near duplication from account.js.
// Once we completely migrate to the Vue based signin/singup,
// We should clean these logic codes from the account.js

'use strict';

var util = require('../components/util');
var validator = require('../components/validator');
var inputs = require('../components/inputs');
var passwordVisibilityToggle = require('../components/passwordvisibilitytoggle');


/**
 * @private
 * @function
 * @description Registration Submission
 * @param el scope element: empty means full page
 */
var submitRegistrationForm = function (el) {
	var _triggeredID = $(".registration .formactions", el).data("action");
	$("#RegistrationForm", el).append("<input name='" + _triggeredID + "' id='" + _triggeredID + "' type='hidden' />");

	if (el) {
		var event = new Event('submit', {
			'bubbles': true,
			'cancelable': true
		});
		el.querySelector("form.registration").dispatchEvent(event);
	} else {
		$("#RegistrationForm").submit();
	}
};

/**
 * @private
 * @function
 * @description init events for the loginPage
 * @param el scope element: empty means full page
 */
var initializeDOM = function (el) {

	if ($(".errorform", el).length > 0) {
		dataLayer.push({'event': 'accountSignIn', 'successOrFailure': 'failure'});
	}

	var $term = $("#termsAndConditionsAgreement", el);
	$term.length && $term.rules('add', {checkboxRequired: true});

	// REGISTRATION PAGE
	if ($(".registration", el).length > 0) {
		// clear out formfields that could be part of autocomplete
		$("input.textinputpw", el).attr('autocomplete', 'off').val('');
		$("input.email-confirm", el).attr('autocomplete', 'off').val('');
	}


	// LOGIN / REGISTRATION PAGE
	var $loginRegisterBox = $('.login-register-box', el);
	if ($loginRegisterBox && $loginRegisterBox.length) {
		$loginRegisterBox.tabs({active: $loginRegisterBox.data('tab-active')});
		passwordVisibilityToggle.init($loginRegisterBox);
	}

	// JOIN NATURE.REWARDS PAGE
	var $natureRewardPanel = $('.pt_account.rewards-registration .join-rewards-panel', el);
	if ($natureRewardPanel && $natureRewardPanel.length) {
		$natureRewardPanel.tabs({active: $natureRewardPanel.data('active-tab')});
		passwordVisibilityToggle.init($natureRewardPanel);
	}

	// Format fields for better UI
	util.updateInputTypes($('input[name$="_username"]', el), 'email');
	util.updateInputTypes($('input[name$="_customer_email"]', el), 'email');

	// Remove 'placeholder' class if the dob fields have value selected.
	$("select.birth-month, select.birth-date").each(function () {
		var $el = $(this);
		if ($el.val()) {
			$el.removeClass('placeholder');
		}
	});
};

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 * @param el scope element: empty means full page
 */
var initializeEvents = function (el) {

	if (el) {
		// initialize all input fields inside the signup wrapper
		// this only happen when it is triggered by signup modal popup
		inputs.init(el);
	}

	// REGISTRATION
	if ($("form.registration", el).length > 0) {

		var $mobile = $('form.registration .mobile', el);
		var $country = $('input.country', el);
		var countryCode = $country.val();

		if ($mobile.length) {
			if (countryCode == 'US' || countryCode == 'CA') {
				$mobile.mask('000-000-0000');
			}

			var $smsOptChk = $("form.registration input[name$='_addToSMSList']", el);
			if ($smsOptChk.length) {
				// If SMS opt-in checkbox is available, make the mobile phone number field mandatory
				// while the checkbox is selected.
				var toggleMobileMandatory = function () {
					if ($smsOptChk.is(":checked")) {
						$mobile.addClass('required');
						$mobile.rules('add', {
							required: true,
							messages: {
								required: Resources.VALIDATE_MOBILE
							}
						});
					} else {
						$mobile.removeClass('required');
						$mobile.rules('remove', 'required');
					}
					// Trigger validation
					$mobile.trigger('blur');
				};
				toggleMobileMandatory();
				$smsOptChk.on('change', function () {
					toggleMobileMandatory();
				});
			}
		}

		$("form.registration", el).on("keypress", function (e) {
			if (e.which == 13) {
				e.preventDefault();
				submitRegistrationForm(el);
			}
		});

		$('.registration', el).on("click", ".create-account-btn", function (e) {
			e.preventDefault();
			submitRegistrationForm(el);
		});
	}

	if ($(".account-promembership", el).length > 0) {
		var $scope = el ? $(el) : $(".pt_account");
		$scope.on("click", "button.reapply", function () {
			window.location = $(this).data("url");
		});

	}

	$("select.birth-month, select.birth-date", el).on('change', function () {
		if (!this.value.length) {
			$(this).addClass("placeholder");
		} else {
			$(this).removeClass("placeholder");
		}
	});

	$("select.birth-date", el).on('change', function () {
		if ($(this).valid()) {
			$(this).parent(".field-error").removeClass("field-error").find("span.field-error").remove();
		}
	});

	$("select.birth-month", el).on('change', function () {
		var $birthDateEl = $('select.birth-date', el);
		if ($birthDateEl.valid()) {
			$birthDateEl.parent(".field-error").removeClass("field-error").find("span.field-error").remove();
		}

		if (!this.value.length) {
			$birthDateEl.val("");
			$birthDateEl.trigger("change");
		}
	});

};

var signupProcess = {
	init: function (el) {
		// when `el` is NOT empty, this func is called by a modal popup
		// otherwise, it's called from page based init
		window.deferJquery(() => {
			if (el) {
				validator.init(el);
			}

			initializeDOM(el);
			initializeEvents(el);
		});
	},
	isValid: function (formEl) {
		return $(formEl).valid();
	},
	getData: function (formEl) {
		const bodyFormData = new FormData();

		$(formEl).serializeArray().forEach((item) => {
			bodyFormData.append(item.name, item.value);
		});

		const btnName = $("button.btn", formEl).attr("name");
		if (btnName) {
			bodyFormData.append(btnName, "");
		}
		return bodyFormData;
	}
};

module.exports = signupProcess;

// expose signupProcess as an global variable to make itself available in Vue components
window.appSignUpProcess = signupProcess;
