'use strict';

/**
 * @function passwordVisibilityToggle
 * @description
 */
var passwordVisibilityToggle = {
	init: function(el) {
		$('.visibility-toggle', el).click(function() {
			var $this = $(this);
			if ($this.hasClass('visible')) {
				$this.removeClass('visible').addClass('invisible');
				$this.siblings('input').attr('type', 'password');
				$this.attr('aria-label', $this.data('show-label'));
			} else {
				$this.removeClass('invisible').addClass('visible');
				$this.siblings('input').attr('type', 'text');
				$this.attr('aria-label', $this.data('hide-label'));
			}
		});
	}
};

module.exports = passwordVisibilityToggle;
