var accordion = require('./accordion');

var cnc = {
	injectRadioButtons: function(container, stores) {
		if (container) {
			if (stores && stores.length > 0) {
				stores.forEach((store, index) => {
					const storeCity = "<span class='store-city'>" + store.city + "</span>";
					const storeState = store.stateCode ? ", <span class='store-state'>" + store.stateCode + "</span>" : "";
					const leadTimeMsg = store.orderLeadTimeMessage ? " - <span class='store-lead-time'>" + store.orderLeadTimeMessage + "</span>" : "";
					const labelHtml =
						"<div class='store-info'>" +
						"<div class='store-name label-size-large'>" + store.name.substring(store.name.indexOf(' ')) + "</div>" +
						"<div class='text-size-xsmall'>" + storeCity + storeState + leadTimeMsg + "</div>" +
						"</div>";
					container.appendChild(
						this.createRadiobutton(JSON.stringify(store), labelHtml, false, "cnc-radio-button-" + index)
					);
				});
			}
		}
	},

	createRadiobutton: function(value, labelHtml, defaultDisabled, id) {
		const wrapperElm = document.createElement("div");
		const radioElm = document.createElement("input");
		const labelElm = document.createElement("label");

		radioElm.setAttribute("type", "radio");
		radioElm.setAttribute("required", "");
		radioElm.setAttribute("name", "store-selection");
		radioElm.setAttribute("value", value);
		radioElm.setAttribute("id", id);
		radioElm.setAttribute("class", "focusable");
		if (defaultDisabled) {
			radioElm.setAttribute("disabled", "");
		}

		labelElm.setAttribute("class", "store-radio-label");
		labelElm.setAttribute("for", id);
		labelElm.innerHTML = labelHtml;

		wrapperElm.setAttribute("class", "store-radio-wrapper");
		wrapperElm.appendChild(radioElm);
		wrapperElm.appendChild(labelElm);

		return wrapperElm;
	},

	toggleContent: function(showRadioBtn) {
		if (showRadioBtn) {
			$(".section-store-details").fadeOut(250);
			$(".click-and-collect-hint").show();
			$(".click-and-collect-hint .heading-selection").show();
			$(".click-and-collect-hint .heading-summary").hide();
		} else {
			$(".section-store-details").fadeIn(250);
			$(".click-and-collect-hint").show();
			$(".click-and-collect-hint .heading-selection").hide();
			$(".click-and-collect-hint .heading-summary").show();
		}

	},

	toggleToPickupSummary: function() {
		accordion.closeAccordion($("#storeSelectionRadioGroup"));
		this.toggleContent(false);
	},

	toggleToStoreSelection: function() {
		accordion.openAccordion($("#storeSelectionRadioGroup"));
		this.toggleContent(true);
	},

	init: function(container, stores, isStorePreselected) {
		let isKeyboardNav = false;

		if (container) {
			if ($("#storeSelectionRadioGroup .store-radio-wrapper").length === 0) {
				this.injectRadioButtons(container, stores);

				accordion.attach(
					".section-click-and-collect",
					"#storeName.focusable",
					"#storeSelectionRadioGroup",
					"closed",
					() => {
						this.toggleContent(true);
					});
			}

			if (isStorePreselected) {
				this.toggleToPickupSummary();
			} else {
				$("#storeSelectionRadioGroup input[type='radio']").prop("checked", false);
				this.toggleToStoreSelection();
			}

			// Signal to enter the keyboard mode for AXE if relevant keyboard activities on radio buttons are detected
			container.addEventListener('keydown', (e) => {
				switch (e.keyCode) {
				case 13: // enter
				case 32: // space
				case 37: // left
				case 38: // up
				case 39: // right
				case 40: // down
					isKeyboardNav = true;
					break;
				default:
					isKeyboardNav = false;
					break;
				}
			});

			// setup some events to reset validation error
			container.addEventListener('click', () => {
				!isKeyboardNav && this.toggleToPickupSummary();
				container.querySelectorAll("span.field-error").forEach((item) => item.remove());
				container.querySelectorAll(".store-radio-wrapper").forEach((item) => item.classList.remove("field-error"));
			});
		}
	}
};

module.exports = cnc;
