var Cleave = require('cleave.js/dist/cleave.js');

var	util = require('./util');

var ccTypePattern = {

	/**
	 * initialize credit card type check and pattern matching function
	 * Cleave.js is global available
	 *
	 * @param {jQuery element} $ccNo   cc number input field
	 * @param {jQuery element} $ccType hidden cc type drop down list
	 */
	init: function ($ccNo, $ccType) {
		// insert card-icon and card-hint div
		$ccNo.parent().addClass("card-type-unknown");
		$ccNo.attr("placeholder", "0000 0000 0000 0000");
		$ccNo.after("<div class='card-icon'></div>");
		$ccNo.after("<div class='card-hint field-error aria-hidden' style='display: none;'>" + Resources.VALIDATE_CARDTYPE + "</div>");

		var $cardTypeHint = $ccNo.parent().find(".card-hint");

		$ccNo.focusout(function() {
			$cardTypeHint.hide();
		});

		var ccTypeList = $ccType.children().toArray().map(function (o) {
			return $(o).val();
		});

		new Cleave($ccNo.get(0), {
			creditCard: true,
			onCreditCardTypeChanged: function(type) {
				// remove all card-type classnames from input wrapper, then add current type back
				$(this.element).parent().removeClass(function(index, className) {
					return className.split(" ").filter(function(name) {
						return name.indexOf("card-type") === 0;
					});
				}).addClass("card-type-" + type.toLowerCase());
				// show or hide card type hint
				if (
					ccTypeList.indexOf(util.matchIBCardType(type)) < 0 &&
					type !== "unknown"
				) {
					// remove validation message from previous check, while type hint appear
					$ccNo.parent().find("span.field-error").remove();
					$cardTypeHint.show();
				} else {
					$cardTypeHint.hide();
				}
				// select matching type in the hidden dropdown
				if (ccTypeList.indexOf(util.matchIBCardType(type)) >= 0) {
					$ccType.val(util.matchIBCardType(type));
				}
			}
		});
	}
};

module.exports = ccTypePattern;
