'use strict';

var util = require('./util');
var AccessibilityHelper = require('../helper/accessibility-helper');

const mobileMax = window.matchMedia('(max-width: 767px)');
const tabletMin = window.matchMedia('(min-width: 768px)');
const tabletMax = window.matchMedia('(max-width: 999px)');
const desktopMin = window.matchMedia('(min-width: 1000px)');

var mobile = {

	init: function () {
		var $mobileMenu = $('.mobile-menu');

		$('.mobile-menu-btn').on("click", function (e) {
			e.preventDefault();
			mobile.openMobileMenu();
		});

		$(".close-mobile-menu").on("click", function (e) {
			e.preventDefault();
			mobile.closeMobileMenu();
		});
		$(".close-mobile-menu").on("keypress", function(e) {
			e.preventDefault();
			AccessibilityHelper.onSpaceOrEnterAction(e, function() {
				mobile.closeMobileMenu();
			});
		});

		$mobileMenu.on("click", "a.nav-expand", function (e) {
			e.preventDefault();

			toggleCategory($(this).closest(".nav-item"));
		});

		$mobileMenu.on("keydown", ".nav-item", function(e) {
			var $this = $(this);
			AccessibilityHelper.onSpaceOrEnterAction(e, function() {
				var $dropMenu = $this.find(".drop-menu");
				if (!$dropMenu.is(":visible")) {
					$dropMenu.slideDown(250);
					$this.attr('aria-expanded', true);
					$this.find(".nav-expand").addClass("open");
				}
				setTimeout(function() {
					$this.find('a.accessible-link:visible').first().trigger('focus');
				}, 700);
			});

			AccessibilityHelper.onEscapeKey(e, function() {
				var $dropMenu = $this.find(".drop-menu");
				if ($dropMenu.is(":visible")) {
					$dropMenu.slideUp(250);
					$this.attr('aria-expanded', false);
					$this.find(".nav-expand").removeClass("open");
				}

				setTimeout(function() {
					$this.trigger('focus');
				}, 700);
			});
		});

		var toggleCategory = function($navItem) {
			var $dropMenu = $navItem.find(".drop-menu");

			if ($dropMenu.is(":visible")) {
				$dropMenu.slideUp(250);
				$navItem.attr('aria-expanded', false);
				$navItem.find(".nav-expand").removeClass("open");
			} else {
				$dropMenu.slideDown(250);
				$navItem.attr('aria-expanded', true);
				$navItem.find(".nav-expand").addClass("open");
			}

			$(".mobile-nav-content .nav-box").find(".drop-menu").each(function () {
				if ($navItem[0].className != $(this).closest(".nav-item")[0].className) {
					$(this).slideUp(250);
					$(this).find(".sub-nav-menu-items").slideUp(250);
					$(this).closest(".nav-item").find(".nav-expand").removeClass("open");
				}
			});
		};

		// event listener on sub-nav expand icons
		$mobileMenu.on("keydown", ".sub-nav-expand", function(e) {
			var $this = $(this);
			AccessibilityHelper.onSpaceOrEnterAction(e, function() {
				e.stopPropagation();
				toggleSubMenu($this);

				setTimeout(function() {
					$this.find('a.accessible-link:visible').first().trigger('focus');
				}, 700);
			});
		});
		$mobileMenu.on("click", ".sub-nav-expand", function(e) {
			e.preventDefault();
			e.stopPropagation();

			toggleSubMenu($(this));
		});

		var toggleSubMenu = function($this) {
			var $subNavItem = $this.closest(".sub-nav-item");

			// open main sub-menu
			var $dropSubMenu = $subNavItem.find(".sub-nav-menu-items");
			if ($dropSubMenu.is(":visible")) {
				$dropSubMenu.slideUp(250);
				$subNavItem.attr('aria-expanded', false);
				$subNavItem.find(".sub-nav-expand").removeClass("open");
			} else {
				$dropSubMenu.slideDown(250);
				$subNavItem.attr('aria-expanded', true);
				$subNavItem.find(".sub-nav-expand").addClass("open");
			}
			$subNavItem.closest(".sub-nav-menu").find(".sub-nav-menu-items").each(function () {
				if ($subNavItem[0].className != $(this).closest(".sub-nav-item")[0].className) {
					$(this).slideUp(250);
					$(this).closest(".sub-nav-item").find(".sub-nav-expand").removeClass("open");
				}
			});
		};

		// prevent event bubble up through sub-nav-menu
		$mobileMenu.on("keydown", ".sub-nav-menu-items", function(e) {
			AccessibilityHelper.onSpaceOrEnterAction(e, function() {
				e.stopPropagation();
			});
		});
		$mobileMenu.on("click", ".sub-nav-menu-items", function (e) {
			e.stopPropagation();
		});

		// event listener on sub-nav titles (next to the icons)
		$mobileMenu.on("keydown", ".sub-nav-item", function(e) {
			var $navIcon = $(this).find(".sub-nav-expand").first();
			if ($navIcon.length === 0) {
				return;
			}
			AccessibilityHelper.onSpaceOrEnterAction(e, function() {
				e.stopPropagation();
				$navIcon.trigger('click');
			});
		});
		$mobileMenu.on("click", ".sub-nav-item", function (e) {
			var $navIcon = $(this).find(".sub-nav-expand").first();
			if ($navIcon.length === 0) {
				return;
			}
			e.preventDefault();
			$navIcon.trigger('click');
		});

		$(".pt_productsearchresult").on("click", ".filter-menu-btn", function (e) {
			e.preventDefault();

			if (tabletMax.matches) {
				var $FMC = $(".filter-menu-content");
				$FMC.addClass("mobile");

				if (!($FMC.is(":visible"))) {
					mobile.openMobileSlideMenu($FMC);
				} else {
					mobile.closeMobileSlideMenu($FMC);
				}
			}
		});

		$(".pt_productsearchresult").on("click", ".mobile-filter-label a.icon, a.mobile-filter-results", function (e) {
			e.preventDefault();
			mobile.closeMobileSlideMenu($(".filter-menu-content"));
		});

		$(window).on('resize', function () {
			if (tabletMin.matches) {
				mobile.closeMobileMenu();
			}

			if (desktopMin.matches) {
				// Expand any refinement sections that were collapsed for mobile refinement menu.
				$(".refinement ul").each(function () {
					if ($(this).find("li.selected").length > 0) {
						$(this).show().parent().find("h3").addClass("visible");
					} else {
						$(this).hide().parent().find("h3").removeClass("visible");
					}
				});

				$("a.mobile-filter-results").hide();
				$(".filter-menu-content").removeClass("mobile").show();
			}

			if (tabletMax.matches) {
				// Open all any refinement sections that were expanded for desktop refinement menu except section with a selection.
				$(".refinement ul").each(function () {
					if ($(this).find("li.selected").length > 0) {
						$(this).show().parent().find("h3").addClass("visible");
					} else {
						$(this).hide().parent().find("h3").removeClass("visible");
					}
				});
				var $FMC = $(".filter-menu-content");
				if (!($FMC.hasClass("mobile"))) {
					$FMC.hide();
				}
			}
		});

		// Collapse all refinement sections on mobile refinement menu on load except when they have a selection.
		if (tabletMax.matches) {
			// Collapse any refinement sections that were expanded for desktop refinement menu except section with a selection.
			$(".refinement ul").each(function () {
				if ($(this).find("li.selected").length > 0) {
					$(this).show().parent().find("h3").addClass("visible");
				} else {
					$(this).hide().parent().find("h3").removeClass("visible");
				}
			});
		}

		mobile.initMobileSearch();
	},

	// ---------
	// Menu Functions

	openMobileMenu: function () {

		// Populate content
		$('.mobile-menu #mobile-nav-items').empty().html($('#nav-items').clone());
		$('.mobile-menu .mobile-nav-country-toggle').empty().html($('.top-bar a.country-toggle').clone());
		$('.mobile-menu #mobile-nav-additional-items').empty().html($('#nav-additional-items').clone());
		$('.mobile-menu #mobile-nav-additional-items').find('.icon-icon-customer .icon-drop-menu').show();
		$('.mobile-menu #mobile-nav-additional-items').find('.icon-icon-store .icon-drop-menu').show();

		// init aria-expanded on sub menu
		$('.mobile-menu .sub-nav-menu-items').closest('.sub-nav-item').attr('aria-expanded', false);

		// Position and show menu
		$('.mobile-menu').show().removeClass('hidden');
		$('body').addClass('mobile-menu-open');

		// focus
		$(".close-mobile-menu").trigger('focus');

		util.scrollHelperForMobileDevices($('.mobile-menu-overlay'));
		util.scrollHelperForMobileDevices($('.mobile-menu'));

		mobile.openCloseMobileSearch(true, 300);
	},

	closeMobileMenu: function () {
		$('body').removeClass('mobile-menu-open');
		$('.mobile-menu').addClass('hidden');
		setTimeout(function () {
			$('.mobile-menu').hide();
		}, 500); // Matches css transition to hide after animate
		$(".mobile-menu-btn").trigger('focus');
		mobile.openCloseMobileSearch(false, 300);
	},


	/**
	 * @function openMobileSlideMenu()
	 * @description Opens a slide menu
	 * @param {Element} menu The targeted selector menu
	 * @param {String} direction Optional direction the menu should open/close from
	 */
	openMobileSlideMenu: function (menu, direction) {
		menu = typeof menu !== 'undefined' ? menu : $('.slide-menu-content');
		direction = typeof direction !== 'undefined' ? direction : 'left';

		if (mobileMax.matches) {
			menu.addClass('mobile');
		}

		setTimeout(function () {
			menu.show("slide", {direction: direction}, 300, function () {
				// Toggle position internal aspects
				menu.find(".slide-menu-header, a.slide-menu-action").addClass("on");
				$('body').addClass('slide-menu-open');
			});

			util.scrollHelperForMobileDevices(menu);
		}, 300);
	},

	/**
	 * @function closeMobileSlideMenu()
	 * @description Closes a slide menu
	 * @param {Element} menu The targeted selector menu
	 * @param {String} direction Optional direction the menu should open/close from
	 */
	closeMobileSlideMenu: function (menu, direction) {
		menu = typeof menu !== 'undefined' ? menu : $('.slide-menu-content');
		direction = typeof direction !== 'undefined' ? direction : 'left';

		// Toggle position internal aspects
		menu.find(".slide-menu-header, a.slide-menu-action").removeClass("on");

		$('body').removeClass('slide-menu-open');
		menu.hide("slide", {direction: direction}, 300, function () {
			menu.removeClass("mobile");
		});

	},
	/**
	 * Sets up the mobile search "reveal on scroll" effect.
	 * It utilises sticky positioning depending on the menu element's scroll position.
	 */
	initMobileSearch() {
		var mobileMenu = $('.mobile-menu');
		if (mobileMenu.length > 0) {
			var mobileMenuHeader = mobileMenu.find('.mobile-menu-header');
			var mobileMenuSearch = mobileMenu.find('.mobile-menu-search');
			var searchHidden = false;
			mobileMenu.on('scroll', function () {
				var navSearchHeight = mobileMenuSearch.height();
				var scrollTop = mobileMenu.scrollTop();
				if (!searchHidden && scrollTop > navSearchHeight) {

					// The scroll position hides the nav search bar at this point.
					// Remove the sticky position from the menu header and hide the search element.
					mobileMenuHeader.removeClass('sticky');
					mobileMenuSearch.addClass('sticky');
					mobileMenuSearch.hide();

					// Give the menu header an extra margin top so that it visually stays where it currently sits.
					mobileMenuHeader.css({
						'margin-top': navSearchHeight
					});
					searchHidden = true;
					mobileMenu.removeClass('mobile-search-open');
				} else if (searchHidden && scrollTop < navSearchHeight) {

					// The scroll position is about to reveal the search bar at this point.
					// Put the sticky position back to the menu header and show the search element.
					mobileMenuHeader.addClass('sticky');
					mobileMenuSearch.removeClass('sticky');
					mobileMenuSearch.show();

					// Reset the margin of the menu header.
					mobileMenuHeader.css({
						'margin-top': 0
					});
					searchHidden = false;
					mobileMenu.addClass('mobile-search-open');
				}
			});
			// Initially closed
			this.openCloseMobileSearch(false);
		}
	},

	/** Opens the side drawer / sidenav search menu.
	 * @param {boolean} open whether to open the drawer or not
	 * @param {number} delay milliseconds to delay the side drawer opening
	 */
	openCloseMobileSearch(open, delay) {
		var mobileNavSearch = $('.mobile-nav-search');
		var mobileMenu = mobileNavSearch.parents('.mobile-menu');
		if (open) {
			// Disabling background scrolling only while search popup is open
			util.disableBackgroundScrolling(true);

			mobileMenu.addClass('mobile-search-open');
			mobileNavSearch.stop().delay(delay ? delay : 0).slideDown(300, function () {
				mobileNavSearch.addClass('open');
			});
		} else {
			// Enabling background scrolling only while search popup is closed
			util.disableBackgroundScrolling(false);

			mobileNavSearch.removeClass('open');
			mobileNavSearch.stop().delay(delay ? delay : 0).slideUp(10, function () {
				mobileMenu.removeClass('mobile-search-open');
			});
		}
	}
};

module.exports = mobile;
