'use strict';

var $loader;

/**
 * @function
 * @description Shows an AJAX-loader on top of a given container
 * @param {Element} container The Element on top of which the AJAX-Loader will be shown
 */
var show = function (container) {
	var target = (!container || $(container).length === 0) ? $('body') : $(container);
	$loader = $loader || $('.loader');
	var loadingIndicator = 'loader-indicator';
	var loading = 'loader';

	if ($loader.length === 0 || container) {
		$loader = $('<div/>').addClass(loading)
			.append($('<div/>').addClass(loadingIndicator).append('<div></div><div></div><div></div><div></div>'), $('<div/>').addClass('loader-bg'));
	}
	return $loader.appendTo(target.show()).show();
};
/**
 * @function
 * @description Hides an AJAX-loader
 */
var hide = function (container) {

	if (container) {
		container.hide();
		container.find(".loader").remove();
		return;
	}

	if ($loader) {
		$loader.hide();
	}
};

exports.show = show;
exports.hide = hide;
