'use strict';

var progress = require('./progress');
var anime = require('animejs');
var gtmTracking = require('./gtmTracking');
var AccessibilityHelper = require('../helper/accessibility-helper');
var UiEventHelper = require('../helper/ui-event-helper');

var currentSubtotal;
var newSubtotal;


const mobileMax = window.matchMedia('(max-width: 767px)');
const tabletMin = window.matchMedia('(min-width: 768px)');

function refreshTooltips() {

	// Employee discount toggled
	Tipped.create('.employee-discount-toggle', 'toggle-discount-info-tooltip-content', {inline: true, skin: 'white', maxWidth: 200});
}

/**
* Update minicart with new markup from server
* Re-initialize any internal event handlers onto new DOM nodes
* @param {HTML} data new markup to update
*/
function updateCartData(data, $btnElm) {
	$('.bag-content').html(data);
	refreshTooltips();
	var _qty = $('.checkout-bag').data("qty");
	$('.mybag span.qty').html(_qty+"");

	if (_qty <= 0) {
		$(".mybag .bag-icon.full").removeClass("full");
	}

	$.ajax({url: EmarsysUrls.emarsysAddToCartAjax}).done(function (data) {
		if (data) {
			ScarabQueue.push(['cart', data]);
			ScarabQueue.push(['go']);
		}
	});

	// Push GTM Cart Action Data
	gtmTracking.pushCartAction($btnElm);
}

function desktopAddAnimate() {
	var $bagContent = $('.bag-content');
	var $subTotal = $('.js-subtotal');
	var $qty = $('.bag-icon .qty');
	var qtyTotalUpdated = false;

	/**
	* Mask the most recently added product
	* Get this value from .bag-content data-new-product
	*/
	var newProductID = $bagContent.data('new-product');
	var $newProduct = $bagContent.find('.summaryproduct[data-pid=' + newProductID+ ']');
	var $mask = $('.summaryproduct-mask').detach();
	$newProduct.append($mask);
	$mask.removeClass('hidden');

	// save new subtotal, replace with old subtotal, so it can be
	// updated in animation. Subtract 1 from order quantity
	// Increament quantity in animation
	newSubtotal = $subTotal.text();
	$subTotal.text(currentSubtotal);
	$qty.text((parseInt($qty.text(), 10) - 1).toFixed());

	// show the cart after a small delay
	window.setTimeout(function() {
		$bagContent.show().addClass("open");
	}, 300);

	var desktopAddTimeline = anime.timeline({
		autoplay: false,
		update: function(anim) {
			// update the value of the cart quantity and the order subtotal
			// anim.currentTime should sync with the translateY bump up animation
			if (anim.currentTime < 1850 && anim.currentTime > 1800 && !qtyTotalUpdated) {
				$qty.text((parseInt($qty.text(), 10) + 1).toFixed());
				$subTotal.text(newSubtotal);
				qtyTotalUpdated = true;
			}
		},
		complete: function() {
			currentSubtotal = newSubtotal;
			$mask.remove();
			window.setTimeout(function() {
				$bagContent.removeClass("open");
			}, 3000);
		}
	});

	/**
	* Animation timeline steps
	* 1) Wait, then start fading out mask message
	* 2) Slide awway the mask
	* 3) Bump up cart quantity and order total (will be updated in timeline.update())
	* 4) Bump down cart quantity and order total
	*
	* timeline.complete() sets a timeout to hide the minicart
	*/
	desktopAddTimeline.add({
		targets: $mask.find('.summaryproduct-mask__message')[0],
		opacity: {value: 0, duration: 200, easing: 'easeOutQuad'},
		offset: '+=1400'
	}).add({
		targets: $mask[0],
		width: {value: 0, duration: 450, easing: 'easeOutQuad'},
		offset: '-=100'
	}).add({
		targets: [
			$subTotal[0],
			$('.mybag .icon-icon-bag')[0],
			$qty[0]
		],
		translateY: {value: -5, duration: 200, easing: 'easeOutQuad'},
		offset: '-=100'
	}).add({
		targets: [
			$subTotal[0],
			$('.mybag .icon-icon-bag')[0],
			$qty[0]
		],
		translateY: {value: 0, duration: 200, easing: 'easeInQuad'},
		offset: '-=100'
	});

	desktopAddTimeline.restart();
}

var minicart = {
	init: function () {
		// eventlistner to monitor the action from vue ui atc button
		document.addEventListener("ui-add-to-cart", (event) => {
			if (event.detail && event.detail.cartData) {
				this.add(
					"",
					event.detail.cartData,
					() => {
						// inform the vue ui atc buttons that item was added successfully
						const atcBtns = document.querySelectorAll(
							"[id^='ui-add-to-cart-']");
						atcBtns.forEach((btnElm) => {
							btnElm.dispatchEvent(
								new CustomEvent("minicart-added", {
									detail: {
										pid: event.detail.cartData.pid
									}
								})
							);
						});

						if (event.detail.gtmData) {
							window.dataLayer = window.dataLayer || [];
							window.dataLayer.push(event.detail.gtmData);
						}

						UiEventHelper.openCart(true); // open cart and refresh cart content
					}
				);
			}
		}, false);

		// eventlistner on the line item count change sent from cart v3 ui
		document.addEventListener("ui-update-minicart-count", (event) => {
			if (event.detail && event.detail.quantity >= 0) {
				$('.mybag span.qty').html(event.detail.quantity+"");
			}
		}, false);

		if (!window.featureToggles["CartV3"]) {
			var $container = $('.mybag');

			// Init tooltips
			refreshTooltips();

			// save current subtotal so it can be updated in animation
			currentSubtotal = $('.js-subtotal').text();

			$container.on("click", ".continue-shopping button, .mask", function(e) {
				// clicking continue button or mask outside of mobile drawer
				if (e.target == e.currentTarget) {
					$('.mybag').removeClass("open-mobiledrawer");
				}
			});

			// Escape key triggers close mobile drawer function
			$container.on('keydown', function(e) {
				AccessibilityHelper.onEscapeKey(e, function() {
					$container.removeClass("open-mobiledrawer");
				});
			});

			// remove minicart product
			$container.on("click", "a.remove-product-mini", function(e) {
				e.preventDefault();

				var $this = $(this);
				var _href = $this.attr("href");

				progress.show($('.bag-content'));

				$.get(_href, function( data ) {
					if (data) {
						updateCartData(data, $this);
					} else {
						progress.hide();
					}
				});
			});

			// update minicart product quantity
			$container.on("click", ".qty-box", function(e) {
				e.preventDefault();
				updateLineQuantity($(this));
			});
			$container.on("keypress", ".qty-box", function(e) {
				e.preventDefault();

				var $this = $(this);
				AccessibilityHelper.onSpaceOrEnterAction(e, function() {
					updateLineQuantity($this);
				});
			});

			var updateLineQuantity = function($this) {
				var qty = $this.data('value');
				var href = $this.data('updatecart');

				if (qty === 0) {
					// do nothing, minus button is disabled
					// user must click the 'x' to remove from cart
				} else {
					progress.show($('.bag-content'));

					$.get(href, function(data) {
						if (data) {
							updateCartData(data, $this);
						} else {
							progress.hide();
						}
					});
				}
			};

			// toggle discount employee
			$container.on("click", ".employee-toggle", function() {
				var $this = $(this);

				var _href = $this.data('toggleemployee') + '?source=minicart';

				progress.show($('.bag-content'));

				$.get(_href, function( data ) {
					if (data) {
						$('.bag-content').html(data);
						refreshTooltips();
					} else {
						progress.hide();
					}
				});
			});
		}
	},

	/**
	 * @function initCartV3
	 * @description set up open cart event on clicking cart icon in header
	 */
	initCartV3: function() {
		$(".mybag .bag-icon").on('click', function(e) {
			e.preventDefault();
			UiEventHelper.openCart(false); // open cart without refreshing cart content
		});
	},

	add: function(progressImageSrc, postdata, callback) {
		// get button reference
		var addButtons = [];

		// the button to update
		var addButton = null;

		// it is an array of buttons, but we need only one all
		// other combinations are strange so far
		if (addButtons.length == 1)	{
			addButton = addButtons[0];
		}

		var previousImageSrc = null;

		// show progress indicator
		if (addButton != null) {
			previousImageSrc = addButton.src;
			addButton.src = progressImageSrc;
		}

		// handles successful add to cart
		var handlerFunc = (req) => {
			// hide progress indicator
			if (addButton != null) {
				addButton.src = previousImageSrc;
			}

			$('.mybag').html(req);

			if (window.featureToggles["CartV3"]) {
				// re-init bag icon click event for Cart v3
				this.initCartV3();
			} else if (mobileMax.matches) {
				// only apply mobile drawer styling in next rendering cycle
				window.setTimeout(function() {
					$('.mybag').addClass("open-mobiledrawer");
				}, 0);
			} else {
				desktopAddAnimate();
			}

			if (callback) {
				callback();
			}

			$.ajax({url: EmarsysUrls.emarsysAddToCartAjax}).done(function (data) {
				if (data) {
					ScarabQueue.push(['cart', data]);
					ScarabQueue.push(['go']);
				}
			});

		};

		// handles add to cart error
		var errFunc = function() {
			// hide progress indicator
			if (addButton != null) {
				addButton.src = previousImageSrc;
			}
		};

		// add the product
		$.ajax({
			type: "POST",
			url: Urls.minicart,
			cache: true,
			data: postdata,
			success: handlerFunc,
			error: errFunc
		});
	},

	show: function() {
		if (tabletMin.matches) {
			// show the desktop minicart
			$(".bag-content").show().addClass("open");
			// hide the cart
			setTimeout('$(".bag-content").removeClass("open");', 4000);
		}
	}
};

module.exports = minicart;
