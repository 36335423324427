/**
 * UI events helper
 * 	- This file host all events that used to communicate between old app.js and
 * 		the new vue.js world
 */

var UiEventHelper = {
	openCart: function(refreshCart = true) {
		if (window.featureToggles["CartV3"]) {
			try {
				const sEvent = new CustomEvent(
					"ui-open-cart",
					{
						detail: {
							refresh: refreshCart
						}
					});
				document.dispatchEvent(sEvent);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		}
	},
	closeCart: function(refreshCart = true) {
		if (window.featureToggles["CartV3"]) {
			try {
				const sEvent = new CustomEvent(
					"ui-close-cart",
					{
						detail: {
							refresh: refreshCart
						}
					});
				document.dispatchEvent(sEvent);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		}
	},
	refreshCart: function() {
		if (window.featureToggles["CartV3"]) {
			try {
				const sEvent = new CustomEvent("ui-refresh-cart");
				document.dispatchEvent(sEvent);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		}
	}
};

module.exports = UiEventHelper;
