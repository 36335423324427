'use strict';

var debounce = require('lodash/debounce');

var util = require('../../components/util');
var mobile = require('../../components/mobile');
var address = require('../../components/address');
var billing = require('./billing');
var shipping = require('./shipping');
var progress = require('../../components/progress');
var formPrepare = require('../../components/formPrepare');
var updateSummaryDebounce;

var refreshTooltips = function() {

	// Change Country EU & UK only
	if ($(".change-country").length > 0) {
		Tipped.create(".change-country", "changeCountryTooltip", {
			inline: true,
			maxWidth: 200,
			skin: 'icebreaker-primary',
			hook: 'topleft',
			hideOn: 'click-outside'
		});
	}

	// When will my Icebreaker Arrive?
	// Set max width for tooltip on mobile since this tooltip has a lot of content
	if ($("#ShippingTipContent").length > 0) {
		if ($(window).width() > 650) {
			Tipped.create('#ShippingTip', 'ShippingTipContent', {inline: true, skin: 'icebreaker-primary'});
		} else {
			Tipped.create('#ShippingTip', 'ShippingTipContent', {inline: true, maxWidth: 350, skin: 'icebreaker-primary'});
		}
	}

	// Sales Tax (US,CA only)
	if ($("#tax-tip").length > 0) {
		Tipped.create('#tax-tip', 'salesTaxTooltip', {
			inline: true,
			skin: 'icebreaker-primary',
			maxWidth: 200}
		);
	}

	// Subtotal tooltip
	if ($(".checkout-summary .total-tip").length > 0) {
		Tipped.create('.checkout-summary .total-tip', 'bagTotalTooltip', {
			inline: true,
			skin: 'icebreaker-primary',
			maxWidth: 200}
		);
	}

	// Colorado retail delivery fees tooltip
	if ($(".retail-delivery-fees").length > 0) {
		Tipped.create('.retail-delivery-fees', 'shippingLevyDescriptionTooltip', {
			inline: true,
			skin: 'icebreaker-primary',
			maxWidth: 200}
		);
	}
};

/**
 * @function updateSummary()
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
var updateSummary = function(step) {

	if (!updateSummaryDebounce) {
		// Use "debounce" to wait for the specified amount of time before making
		// the call to update the summary block.
		updateSummaryDebounce = debounce(() => {
			var $summary = $('.checkout-summary');

			progress.show($summary);

			$summary.load(Urls.summaryRefreshURL+"?cpt="+step, function () {

				$summary.fadeIn('fast');
				refreshTooltips();

			});
		}, 500);
	}

	updateSummaryDebounce();

};

/**
 * @function toggleOrderInfoSlide()
 * @description toggle slide in/out effect of order info
 */
var toggleOrderInfoSlide = function(action) {
	var $slideWrapper = $(".pt_checkout .order-summary .info-slide-wrapper");
	var $mask = $(".pt_checkout .order-summary .info-slide-overlay");

	if (!($slideWrapper.is(":visible")) || (action != 'close')) {
		$mask.addClass('open');
		mobile.openMobileSlideMenu($slideWrapper, 'right');
	} else {
		mobile.closeMobileSlideMenu($slideWrapper, 'right');
		$mask.removeClass('open');
	}
};

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
var addressInit = function () {
	var $form = $('.address');
	// select address from list
	$('select[name$="_addressList"]', $form).on('change', function () {
		var selected = $(this).children(':selected').first();
		var selectedAddress = $(selected).data('address');
		if (!selectedAddress) {
			return;
		}
		util.fillAddressFields(selectedAddress, $form);
		shipping.updateShippingMethodList();
		// re-validate the form
		$form.validate().form();
	});
};

/**
 * @private
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
var initializeDOM = function() {

	var $checkoutcntr; var $checkoutform;
	var $country = $("select[name$='_country']");
	var $phone = $("input[name$='_phone']");

	addressInit();

	if ($('.checkout-shipping').length > 0) {
		$checkoutcntr = $(".checkout-shipping");
		$checkoutform = $(".shipping-form");
	} else if ($('.checkout-billing').length > 0) {
		$checkoutcntr = $(".checkout-billing");
		$checkoutform = $(".billing-form");
	}

	if ($checkoutcntr) {
		var _siteID = $checkoutcntr.data("siteid");
		var _postalMasks = $checkoutcntr.data("postalmasking");
		var _shipToCountry = $checkoutcntr.data("shiptocountry");

		if (_siteID == "US" || _siteID == "CA") {
			$phone.mask('000-000-0000');

			// Sales Tax (US,CA only)
			if ($("#tax-tip").length > 0) {
				Tipped.create('#tax-tip', 'salesTaxTooltip', {
					inline: true,
					skin: 'icebreaker-primary',
					maxWidth: 200
				});
			}
		}

		if (_siteID == "US") {
			util.updateInputTypes($('input[name$="_zip"]'), 'tel');
		}

		if (_siteID == "EU") {
			$country.val(_shipToCountry);

			if (_shipToCountry == "IE") {
				address.changePostalCode("IE", _postalMasks);
			}
		}

		// update state label upon entering page
		if ($checkoutform) {
			address.updateState($country.val(), $checkoutform.data("state"));
		}

		address.changePostalCode(_shipToCountry, _postalMasks);

		if (_postalMasks != null && _postalMasks.length > 0) {
			address.changePostalMask(_shipToCountry, _postalMasks);
		}

		// update state label upon country selection
		$country.change( function() {

			formPrepare.resetForm();

			var countryCode = $(this).val();
			address.updateState(countryCode, "");

			if ($(".checkout-shipping, .checkout-billing").length > 0) {
				if (countryCode == "US" || countryCode == "CA") {
					$('.textinput.phone').mask('000-000-0000');
				} else {
					$('.textinput.phone').unmask();
				}

				address.changePostalCode(countryCode, _postalMasks);
			}

			// updatePaymentMethods(countryCode);
			return false;
		});
	}

	// Alert form field upon paypal error message after verify if form and paypalError exist DX-2652
	if ($(".checkout-shipping, .checkout-billing").length > 0 && $('.paypal-error').length > 0) {
		// switch field-success to field-error to visually reflect paypal error message on form
		$('.inputfield.zip').switchClass('field-success', 'field-error');
		$('.inputfield.state').switchClass('field-success', 'field-error');
		$('.inputfield.city').switchClass('field-success', 'field-error');
	}

	if ($('.checkout-shipping').length > 0) {
		shipping.init();
	} else if ($('.checkout-billing').length > 0) {
		billing.init();
	}
};

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
var initializeEvents = function() {

	// Checkout mobile order info slide
	$(".pt_checkout .checkout-summary-container").on("click", ".pre-info", function(e) {
		e.preventDefault();
		toggleOrderInfoSlide();
	});

	$(".pt_checkout .checkout-summary-container").on("click", ".info-slide-wrapper .info-header .icon-icon-close, .info-slide-overlay", function(e) {
		e.preventDefault();
		toggleOrderInfoSlide('close');
	});
	// -----
};

exports.init = function () {
	initializeDOM();
	initializeEvents();
};

exports.refreshTooltips = refreshTooltips;
exports.updateSummary = updateSummary;

const pageEntry = {
	init: function () {
		initializeDOM();
		initializeEvents();
	}
};

if (!window.appPages) {
	window.appPages = {};
}

window.appPages.checkout = pageEntry;
