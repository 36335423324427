var AccessibilityHelper = {
	onSpaceOrEnterAction: function (event, callback) {
		var keyCode = event.keyCode || window.event.keyCode;

		if (keyCode == 13 || keyCode == 32) {
			callback();
		}
	},
	onEscapeKey: function (event, callback) {
		var keyCode = event.keyCode || window.event.keyCode;

		if (keyCode == 27) {
			callback();
		}
	},
	/**
	 * While a content is hidden, we need to disable accessibility for
	 * all the focusable elements in it, including moving focus by tabs. For example,
	 * this function can be called when accordion gets closed/opened.
	 *
	 * @param container
	 * @param accessibilityOn
	 */
	toggleAccessibility: (container, accessibilityOn) => {
		const elements = container.find('input, select, textarea, button, a');
		if (accessibilityOn) {
			container.removeAttr('tabindex');
			container.attr('aria-hidden', 'false');
			elements.removeAttr('tabindex');
			elements.attr('aria-hidden', 'false');
		} else {
			container.attr('tabindex', '-1');
			container.attr('aria-hidden', 'true');
			elements.attr('tabindex', '-1');
			elements.attr('aria-hidden', 'true');
		}
	}
};

module.exports = AccessibilityHelper;
