'use strict';

var accordion = require('./accordion');

var address = {
	/**
	 * @function updateState()
	 * @description change available states based on country/territory
	 */
	updateState: function(countryCode, savedState) {

		var _newOptions = null;
		var _label = Resources.STATE_LABEL;
		var _initialOption = "";

		var $state = $("select[name$='_state']");
		var $stateUS = $("select[name$='_stateUS']");
		var $stateCA = $("select[name$='_stateCA']");
		var $stateAU = $("select[name$='_stateAU']");

		if (countryCode == "US") {
			_initialOption = "";
			$state.attr("disabled", false);
			_newOptions = $stateUS.children().clone();
		} else if (countryCode == "CA") {
			_initialOption = "";
			_label = Resources.PROVINCE_LABEL;
			$state.attr("disabled", false);
			_newOptions = $stateCA.children().clone();
		} else if (countryCode == "AU") {
			_initialOption = "";
			$state.attr("disabled", false);
			_newOptions = $stateAU.children().clone();
		} else {
			$(".value.state").hide();
			$(".value.state").parent('.col-state').hide();

			$state.attr("disabled", true);
			$state.removeClass("field-error");
			$state.removeClass("required");
		}

		if (countryCode == "UK" || countryCode == "GB") {
			$(".value.county").show();
			$(".value.county").parent('.col-county').show();
		} else {
			$(".value.county").hide();
			$(".value.county").parent('.col-county').hide();
		}

		if (_newOptions != null) {
			$state.children().remove();
			$state.append(_newOptions);
			$state.addClass("required");
			$(".value.state").show();
			$(".value.state").parent('.col-state').show();
		}

		$(".label.state span.labeltext").html(_label);

		if (savedState != "") {
			_initialOption = savedState;
		}

		this.changeFormSelection($state[0], _initialOption);
	},

	/**
	 * @function changeFormSelection()
	 * @description changes the selection of the given form select to the given value
	 */
	changeFormSelection: function(selectElem, selectedValue) {
		if (!selectElem) return;
		var options = selectElem.options;
		if (options.length > 0) {
			// find index of value to select
			var idx = -1;
			for (var i=0; i<options.length; i++) {
				if (options[i].value == selectedValue) {
					idx = i;
					break;
				}
			}

			if ( idx < 0 ) {
				selectElem.selectedIndex = 0;
				return (false);
			}

			selectElem.selectedIndex = idx;
			return (true);
		}

		return (false);
	},

	/**
	 * @function containsSelectionElement()
	 * @description
	 */
	containsSelectionElement: function(selectElem, selectedValue) {
		if (!selectElem) return;
		var options = selectElem.options;
		if (options.length > 0) {
			for (var i=0; i<options.length; i++) {
				if (options[i].value == selectedValue) return true;
			}
		}
		return false;
	},

	/**
	 * @function changePostalCode()
	 * @description
	 */
	changePostalCode: function(countryCode, postalMasks) {
		if (countryCode == "IE") {
			$(".label.zip, .value.zip").hide();
			$("input.postalcode").removeClass("required");
		} else if (countryCode == "UK" || countryCode == "GB") {
			$(".label.zip span.labeltext").html(Resources.POSTCODE_LABEL);
			$("input.postalcode").addClass("required");
			// $("input.postalcode").val("");
			$(".label.zip, .value.zip").show();
		} else if (countryCode == "US") {
			$(".label.zip span.labeltext").html(Resources.ZIP_LABEL);
			$("input.postalcode").addClass("required");
			// $("input.postalcode").val("");
			$(".label.zip, .value.zip").show();
		} else {
			$(".label.zip span.labeltext").html(Resources.POSTALCODE_LABEL);
			$("input.postalcode").addClass("required");
			// $("input.postalcode").val("");
			$(".label.zip, .value.zip").show();
		}

		this.changePostalMask(countryCode, postalMasks);
	},

	/**
	 * @function changePostalMask()
	 * @description
	 */
	changePostalMask: function(countryCode, postalMasks) {

		var _postalCodeMaskMapping = postalMasks ? postalMasks.split(",") : [];
		var _postalMask = "";
		var _maskArr = null;

		_postalCodeMaskMapping.forEach( function(item) {
			_maskArr = item.split("|");
			if (_maskArr[0] == countryCode) {
				_postalMask = _maskArr[1];
			}
		});

		var $postalCode = $("input[name$='_zip']");
		var $invoicePostalCode = $("input[name$='_invoiceZipcode']");
		if (countryCode === "UK" || countryCode === "GB") {
			$postalCode.unmask();
			$invoicePostalCode.unmask();
		} else {
			$postalCode.mask(_postalMask);
			$invoicePostalCode.mask(_postalMask);
		}
	},

	/**
	 * @function showAddressForm()
	 * @description
	 */
	showAddressForm: function(element, trigger) {
		if ($(element).is(trigger)) {
			accordion.openAccordion($('#address-form .accordion-content'));

		} else {
			accordion.closeAccordion($('#address-form .accordion-content'));
		}
	}
};


module.exports = address;
