'use strict';

var gtmFunction = {

	pushAnalyticsEvent: function($elm) {
		if (typeof window.analyticsEvent != 'function') {
			return;
		}

		var $gtmDataContainer = $elm.find('.gtm-data');
		if ($gtmDataContainer.length) {
			var trackingData = $gtmDataContainer.data('gtm');

			// GTM `analyticsEvent` function definition -
			// ƒ (a,b,c,d,f,e){e=e||{};dataLayer.push({
			// 	event:"analyticsEvent",eventCategory:a,eventAction:b,eventLabel:c,eventValue:d,eventNonInt:f,ecommerce:e
			// })}
			window.analyticsEvent(
				trackingData.Category,
				trackingData.Action,
				trackingData.Label,
				trackingData.Value,
				trackingData.NonInt,
				trackingData.ecommerce
			);
		}
	},

	pushDataLayerJson: function ($elm) {
		var $gtmDataContainer = $elm.find('.gtm-data');

		if ($gtmDataContainer.length) {
			var trackingData = $gtmDataContainer.data('gtm');
			trackingData && dataLayer.push(trackingData);
		}
	},

	/**
	 * @function pushCheckoutAction()
	 * @param {jQuery Element} $elm
	 * @param {Object} option as one extra option in actionField, viable key can be `checkout` or `checkout_option`
	 */
	pushCheckoutAction: function ($elm, option) {
		var $gtmDataContainer = $elm.find('.gtm-data');
		if ($gtmDataContainer.length) {
			var trackingData = $gtmDataContainer.data('gtm');
			if (trackingData) {
				if (option) {
					if (Object.keys(option).length == 1) {
						trackingData.ecommerce[Object.keys(option)[0]].actionField.option = option[Object.keys(option)[0]];
					}
				}

				dataLayer.push(trackingData);
			}
		}
	}
};

var gtmTracking = {
	init: function () {
		$('body').on('click.gtmTracking', '[data-gtm-function="onClick"]', function () {
			gtmFunction.pushDataLayerJson($(this));
		});

		$('body').on('click.gtmTracking', '[data-gtm-function="analyticsEvent"]', function () {
			gtmFunction.pushAnalyticsEvent($(this));
		});

		$('[data-gtm-function="onLoad"]').each(function () {
			gtmFunction.pushDataLayerJson($(this));
		});

		window.addEventListener('recommendation-load', function() {
			gtmFunction.pushDataLayerJson($('.pi-recommendation'));
		});
	},
	pushDataLayerJson: gtmFunction.pushDataLayerJson,
	pushCartAction: gtmFunction.pushDataLayerJson,
	pushCheckoutAction: gtmFunction.pushCheckoutAction
};

module.exports = gtmTracking;
